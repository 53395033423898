<template>
   <!-- 退换订单 -->
   <div class="storage"
     v-loading.fullscreen.lock="loading" 
     element-loading-text="请求数据中"
     element-loading-background="rgba(255, 255, 255, 0.5)"
   >
      <div class="append">
        <div class="seach">
         <ShopsAndSites
           v-if="tress.length != 0"
           style="margin-right: 10px"
           :list="tress"
           @getShopId="getID"
         ></ShopsAndSites>
         <el-select style="width:210px; margin-right: 20px" v-model="pageInfoList.status" placeholder="商品状态" clearable @change="tableSearch">
          <el-option
            v-for="item in goodsStatusList"
            :key="item.id"
            :label="item.value"
            :value="item.id">
         </el-option>
         </el-select>
         <el-date-picker
           v-model="timeRange"
           type="daterange"
           range-separator="至"
           start-placeholder="开始日期"
           end-placeholder="结束日期"
           format="yyyy 年 MM 月 dd 日"
           value-format="yyyy-MM-dd"
           style="margin-right: 20px"
         ></el-date-picker>
         <el-input style="width:360px;" v-model="keyword" placeholder="请输入ASIN"  @keyup.enter.native = "tableSearch">
            <el-select 
              clearable 
              @clear="clearAsin"
              slot="prepend"
              v-model="select_Asin" 
              placeholder="选择输入类型" 
              style="width: 130px"
              @change="tableSearch"
              >
              <el-option
                v-for="item in subASINList"
                :key="item.id"
                :label="item.value"
                :value="item.id">
              </el-option>
            </el-select>
         </el-input>
         <el-button type="primary" icon="el-icon-search" @click="tableSearch">查询</el-button>
         <el-button type="primary" icon="el-icon-download" @click="downLoad">下载</el-button>
         <!-- 列表区域 -->
         <el-table
           border
           :data="tableDataList"
           style="width: 100%"
           :header-cell-style="{background:'#EFEFEF',textAlign:'center', height: '50px'}"
          >
            <!-- asin  imageUrl commoditySku -->
            <el-table-column label="商品信息" align="center" width="270">
               <template slot-scope="scope">
                  <el-popover placement="right" width="520" trigger="hover" transition="fade-in-linear" close-delay="30">
                    <!-- 鼠标悬浮 -->
                    <div style="display:flex">
                      <div class="pic">
                        <span v-if="!scope.row.imageUrl">暂无图片</span>
                        <img v-else :src="scope.row.imageUrl" alt="" width="100%" height="100%">
                      </div>
                      <div class="suspend" style="width:320px; font-size:14px">
                        <p class="infoText">{{scope.row.title}}</p>
                        <p style="margin-bottom:10px">
                          <span>
                            <img style="width:20px;margin:0 8px 0 0" :src="scope.row.imageUrl" alt=""/>
                            <p>子ASIN:{{scope.row.asin}}</p>
                          </span>
                         <span>父ASIN:{{scope.row.parentAsin}}</span></p>
                        <p><span>SKU:{{scope.row.commoditySku}}</span><span>品牌:{{scope.row.label}}</span></p>
                      </div>
                    </div>
                    <div class="goodsInfo" slot="reference">
                      <div class="noPic"> 
                         <span v-if="!scope.row.imageUrl">暂无图片</span>
                         <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                      </div>
                      <div style="text-align:left">
                        <!-- <img style="width:20px;margin:0 6px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/> -->
                         <p>子ASIN:&nbsp;&nbsp;{{ scope.row.asin }}</p>
                         <p>SKU:&nbsp;&nbsp;{{ scope.row.commoditySku }}</p>
                         <p>父ASIN:&nbsp;&nbsp;{{ scope.row.parentAsin }}</p>
                      </div>
                    </div>
                  </el-popover>
               </template>
            </el-table-column>
            <el-table-column label="订单号" prop="orderNo" align="center"></el-table-column>
            <el-table-column label="商品数量" prop="returnNumber" align="center"></el-table-column>
            <el-table-column label="退换时间" prop="returnDate" align="center"></el-table-column>
            <el-table-column prop="timeInterval" align="center">
              <template slot="header">
                <span>时间间隔(天)</span>
                <el-tooltip effect="dark" placement="top" content="用户下单的时间距退换货时间的天数">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="orderAmount" align="center">
              <template slot="header">
                <span>退款金额(¥)</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span style="color: #CD853F">买家收到的退款金额。</span><br/>
                    <span>计算规则：退款金额 = 商品价格 + 商品税 + 促销费 + 促销税 + 配送费 + 配送税 + 礼品包装费 + 礼品包装税+其它费用;</span><br/>
                    <span>商品价格 = 商品价格 x 购买数量； 商品税、配送费买家支付时，可能存在也可能不存在;</span><br/>
                    <span>促销费是卖家的优惠，需扣除； 促销税、 配送税、礼品包装费、礼品包装税、其它费用一般情况是没有的;</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="returnAmount" align="center">
              <template slot="header">
                <span>退款费用(¥)</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>退款相关费用，一般是负数，表示亚马逊平台会替卖家支付部分金额。</span><br/>
                    <span>因为佣金会返回80%，其它税也会返给客户，还可能存在亚马逊赔偿。</span><br/>
                    <span>计算规则：退款费用 = 退款佣金 + 配送费 + 礼品包装费 + 订单佣金 + 商品税 + 促销税 +  配送税 + 礼品包装税 + 其它费用;</span><br/>
                    <span>退款佣金 = 订单佣金 x 20%； 礼品包装费、配送费不同地区退款时不一样，如果是买家付，则此项费用为0;</span><br/>
                    <span>商品税、促销税、配送税、礼品包装税，亚马逊如果收取，则全部返还给客户;</span>
                  </div> 
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="" align="center">
              <template slot="header">
                <span>应付金额(¥)</span>
                <el-tooltip effect="dark" placement="top" content="卖家真实需要付的金额">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="退换原因" prop="returnReason" align="center"></el-table-column>
            <el-table-column label="备注" prop="tips" align="center"></el-table-column>
            <el-table-column label="状态" prop="status" align="center">
               <template slot-scope="scope">
                  <span v-if="scope.row.status == 1" style="color: #19B955">已完成</span>
                  <span v-if="scope.row.status == 0" style="color: #f73131">进行中</span>
               </template>
            </el-table-column>
         </el-table>
         <!-- 分页区域 -->
         <div class="Moder_pages">
           <el-pagination 
             @size-change="handleSizeChange" 
             @current-change="handleCurrentChange"
             layout="total, sizes, prev, pager, next, jumper" 
             :page-sizes="[20, 30, 50, 100]" 
             :total="total"
             :page-size="pageInfoList.pageSize" 
             :current-page="pageInfoList.current">
           </el-pagination>
         </div>
        </div>
      </div>
   </div>
</template>

<script>
import ShopsAndSites from "@/components/ShopsAndSites.vue";
import {
   getPageInit,  // 查询初初始化
   getListPage,
   ExportExcel   // 下载事件
}from '@/api/returnOrder.js'
export default {
      components: {
    ShopsAndSites,
  },
    data() {
      return {
        tress: [],
        loading: false,
        pageInfoList: {
          status: "",
          current: 1,
          pageSize: 20
        },
        goodsStatusList: [],
        subASINList: [],
        timeRange: [],   // 用户检索的时间范围
        tableDataList: [],
        total: 0,   // 总条数
        shopId: "",  // 店铺id
        marketPlaceId: "",  // 站点id
        select_Asin: "",
        keyword: "",
      }
    },
    // 生命周期
    created() {
      this.getPageInitData();
    },
    methods: {
      getPageInitData() {
         getPageInit()
          .then(res=> {
            let result = res.data.data;
            if(res.data.code == 200) {
               this.goodsStatusList = result.statusList;
               this.tress = result.tress;
               this.subASINList = result.searchList;
            }
          })
      },
      //  查询事件
      tableSearch() {
         let Sku_Asin = "";
         if(this.select_Asin == "" || this.keyword == "") {
            Sku_Asin = null;
         }else if(this.select_Asin == "asin") {
            Sku_Asin = { asin:this.keyword };
         }else if(this.select_Asin == "parent_asin") {
            Sku_Asin = { parent_asin:this.keyword };
         }else if(this.select_Asin == "commodity_sku") {
            Sku_Asin = { commodity_sku:this.keyword };
         }
         this.loading = true;
         let params = {
            current: this.pageInfoList.current,
            shopId: this.shopId,
            marketPlaceId: this.marketPlaceId,
            status: this.pageInfoList.status,
            beginTime: Array.isArray(this.timeRange)?this.timeRange[0] : "",
            endTime: Array.isArray(this.timeRange)?this.timeRange[1] : "",
            pageSize: this.pageInfoList.pageSize
         }
         getListPage({...Sku_Asin, ...params})
         .then(res=> {
           this.loading = false;
            this.tableDataList = [];
            if(res.data.code == 500){
              this.$message.error(res.data.message);
            }else {
              this.tableDataList = res.data.values;
              this.total = res.data.pageInfo.total;
            }
         })
      },
      clearAsin() {
         this.keyword = "";
         this.select_Asin = ""
      },
      handleSizeChange(newSize) {
         this.pageInfoList.pageSize = newSize;
         this.tableSearch();
      },
      handleCurrentChange(newCurrent) {
         this.pageInfoList.current = newCurrent;
         this.tableSearch();
      },
      getID(shopID, marketPlaceId) {
        this.shopId = shopID;
        this.marketPlaceId = marketPlaceId;
        this.tableSearch();
      },
      // 下载事件
      downLoad() {
        this.loading = true;
        let Sku_Asin = "";
         if(this.select_Asin == "" || this.keyword == "") {
            Sku_Asin = null;
         }else if(this.select_Asin == "asin") {
            Sku_Asin = { asin:this.keyword };
         }else if(this.select_Asin == "parent_asin") {
            Sku_Asin = { parent_asin:this.keyword };
         }else if(this.select_Asin == "commodity_sku") {
            Sku_Asin = { commodity_sku:this.keyword };
        }
        let params = {
          shopId: this.shopId,
          marketPlaceId: this.marketPlaceId,
          status: this.pageInfoList.status,
          beginTime: Array.isArray(this.timeRange)?this.timeRange[0] : "",
          endTime: Array.isArray(this.timeRange)?this.timeRange[1] : "",
        }
        ExportExcel({...Sku_Asin, ...params})
        .then(res=> {
          this.loading = false;
          if(res.data.code == 200) {  
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = res.data.data;
            link.click();
            document.body.removeChild(link);
            this.$message.success(res.data.message);
          }else {
            this.$message.error(res.data.message);
          }
        })
      },
    }
}
</script>

<style lang="scss" scoped>
.storage {
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-input {
        width: 230px;
        margin-right: 20px;
      }
      .el-table {
          margin-top: 30px;
      }
    }
  }
}
.Moder_pages {
   margin-top: 30px;
   text-align: center;
   margin-bottom: 10px;
}
.goodsInfo{
  display: flex;
  >div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  p{
    font-size: 12px;
    color: #909399;
  }
}
.noPic{
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size:8px;
  color: #909399;
  margin-right: 15px;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
.pic{
  width:160px;
  height: 160px;
  font-size:8px;
  color: #909399;
  text-align: center;
  line-height: 160px;
  margin-right: 10px;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
.suspend{
  p{
   span{
     display: inline-block;
     width: 140px;
     margin-right: 10px;
     font-size: 13px;
   }
  }
}
img{
  vertical-align: middle;
}
.infoText{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 25px;
  margin: 15px 0 20px 0;
}

</style>